import BannerImg from 'src/images/app-preview/app-preview-top-banner.png';
import React from 'react';
import styles from './TopBanner.module.scss';

export default function TopBanner() {
  return (
    <section>
      <img alt="banner" src={BannerImg} className={styles.banner} />
    </section>
  );
}
