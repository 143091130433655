import React from 'react';
import styles from './Content.module.scss';
import LinearCTAButton from '../LinearCTAButton';

export default function Content({ title, body, footer, ctaTitle, ctaURL }) {
  return (
    <section className={styles.section}>
      <p className={styles.title}>{title}</p>
      <p className={styles.body}>
        {body}
        <br />
        <br />
        {footer}
      </p>
      <LinearCTAButton className={styles.cta} text={ctaTitle} to={ctaURL} />
    </section>
  );
}
