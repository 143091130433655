import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { Layout } from 'src/components';
import TopBanner from 'src/components/AppPreview/TopBanner';
import Content from 'src/components/AppPreview/Content';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function AppPreview() {
  const isDesktop = useMediaQuery('(min-width:769px)');
  const { pathname } = useLocation();
  const template = pathname.split('/')[2];
  const id = pathname.split('/')[3];
  const ctaURL =
    process.env.GATSBY_ENVIRONMENT === 'production'
      ? 'https://paidy.onelink.me/'
      : 'https://paidy-stg.onelink.me/';

  useEffect(() => {
    if (isDesktop) {
      window.location.href = `${ctaURL + template}/${id}`;
    }
  }, [isDesktop]);

  return (
    <Layout hasNoHeader hasNoFooter>
      <div>
        <TopBanner />
        <Content
          title={'あと払いペイディ'}
          body={
            'スマホだけで今すぐ購入、お支払いは翌月27日まででOK。3・6・12回あと払いが分割手数料無料*。'
          }
          footer={'*口座振替・銀行振込のみ無料'}
          ctaTitle={'アプリを開く'}
          ctaURL={`${ctaURL + template}/${id}`}
        />
      </div>
    </Layout>
  );
}
